import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export const getActiveLanguageCode = () =>
  localStorage.getItem("selectedLanguage") || "en-US";

export const getTranslationFileName = () => {
  const activeLangCode = getActiveLanguageCode();
  switch (activeLangCode) {
    case "en-US":
      return "en";
    case "hy-AM":
      return "hy";
    case "ru-RU":
      return "ru";
    default:
      return "en";
  }
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: getTranslationFileName(),
    debug: false,
    ns: ["common"],
    defaultNS: "common",
    backend: {
      loadPath: "/locale/{{lng}}/{{ns}}.json",
    },
    cache: {
      enabled: true,
    },
    react: {
      useSuspense: true,
    },
    load: "languageOnly",
    fallbackLng: false,
  });

export default i18n;

export const loadTranslations = async (language: string) => {
  return new Promise((resolve) => {
    i18n.changeLanguage(language, () => resolve(true));
  });
};

export function translate(key: string, options?: any): string {
  return i18n.t(key, options).toString();
}
