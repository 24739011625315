import React, { lazy } from "react";
import "./i18n";
import ReactDOM from "react-dom/client";
import { getTranslationFileName, loadTranslations } from "i18n";

const StoreWrapper = lazy((): any => import("./StoreWrapper"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

loadTranslations(getTranslationFileName()).then(() => {
  root.render(<StoreWrapper />);
});
